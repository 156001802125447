export default {
    state: {
        status: {
            isOpen: false
        }
    },
    mutations: {
        ToggleAppDrawer(state, data = undefined) {
            if (data == null || data == undefined) {
                state.status.isOpen = !state.status.isOpen;
            } else {
                state.status.isOpen = data;
            }
        },
        OpenAppDrawer(state) {
            state.status.isOpen = true;
        },
        CloseAppDrawer(state) {
            state.status.isOpen = false;
        }
    }
}