import api from "./index";
import { catcher } from "./handleError";

export default {
  requestPayout(payout) {
    return api().put(`/api/user/payout`, { payout });
    // .catch(catcher);
  },
  getPaid(limit, page) {
    let ql = limit ? `limit=${limit}` : "";
    let qp = page ? `page=${page}` : "";
    let query = "?";
    if (ql) query += ql + "&";
    if (qp) query += qp + "&";
    query = query.substring(0, query.length - 1);
    return api().get(`/api/paid${query}`);
  }
};
