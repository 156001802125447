<template>
  <div class="background-loading-login" v-if="loading">
    <div class="wrapper">
      <div class="cssload-loader"></div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      loadingCount: state => state.overlay.loading.count
    }),
    loading() {
      return this.loadingCount > 0;
    }
  }
};
</script>
<style scoped>
.background-loading-login {
  position: fixed;
  z-index: 99999999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(255, 255, 255, 0.1);
}
.wrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cssload-loader {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  margin: 5em;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background: rgb(0, 94, 255);
}
.cssload-loader,
.cssload-loader:before,
.cssload-loader:after {
  animation: 0.75s infinite ease-in-out;
  -o-animation: 0.75s infinite ease-in-out;
  -ms-animation: 0.75s infinite ease-in-out;
  -webkit-animation: 0.75s infinite ease-in-out;
  -moz-animation: 0.75s infinite ease-in-out;
}
.cssload-loader:before,
.cssload-loader:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.cssload-loader {
  animation-name: cssload-loader;
  -o-animation-name: cssload-loader;
  -ms-animation-name: cssload-loader;
  -webkit-animation-name: cssload-loader;
  -moz-animation-name: cssload-loader;
}

@keyframes cssload-loader {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 0;
  }
}

@-o-keyframes cssload-loader {
  from {
    -o-transform: scale(0);
    opacity: 1;
  }
  to {
    -o-transform: scale(1);
    opacity: 0;
  }
}

@-ms-keyframes cssload-loader {
  from {
    -ms-transform: scale(0);
    opacity: 1;
  }
  to {
    -ms-transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-loader {
  from {
    -webkit-transform: scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@-moz-keyframes cssload-loader {
  from {
    -moz-transform: scale(0);
    opacity: 1;
  }
  to {
    -moz-transform: scale(1);
    opacity: 0;
  }
}
</style>