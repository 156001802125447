<template>
  <div class="gt_model-error" v-if="error.message" @click="closeBodyError">
    <div class="gt_model-error-body">
      <div class="gt_model-error-image">
        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57">
          <g transform="translate(-732 -89)">
            <circle cx="28.5" cy="28.5" r="28.5" transform="translate(732 89)" fill="#ffecb9" />
            <g id="warning-sign" transform="translate(573.773 107)">
              <path
                d="M186.727,16.1a1.49,1.49,0,0,0,1.484-1.365l1.007-12.026a2.5,2.5,0,1,0-4.983,0l1.007,12.026A1.49,1.49,0,0,0,186.727,16.1Z"
                transform="translate(0)"
                fill="#ffba00"
              />
              <path
                d="M207.076,410.908a1.623,1.623,0,1,0,1.623,1.623A1.622,1.622,0,0,0,207.076,410.908Z"
                transform="translate(-20.348 -393.923)"
                fill="#ffba00"
              />
            </g>
          </g>
        </svg>
      </div>
      <div class="gt_model-error-info">
        <p>{{error.title || "Warning Message"}}</p>
        <span v-html="error.message"></span>
        <button @click.prevent="CloseError">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "ErrorMessage",
  computed: mapState({
    error: state => state.overlay.error
  }),
  methods: {
    ...mapMutations({
      CloseError: "CloseError"
    }),
    closeBodyError() {
      let $target = event.target;
      if (!$target.closest(".gt_model-error-body")) {
        this.CloseError();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.gt_model-error {
  position: fixed;
  z-index: 99999999999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(#000, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.gt_model-error-body {
  padding: 50px 60px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 1px rgba(#000, 0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;

  .gt_model-error-image {
    width: 100px;
    text-align: center;
    margin-bottom: 10px;
    user-select: none;
  }
  .gt_model-error-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;
      margin: 0;
      margin-bottom: 15px;
      user-select: none;
    }
    span {
      margin: 0;
      font-size: 14px;
      overflow: auto;
      display: inline;
      text-align: center;
      max-width: 350px;
      line-height: 1.43;
      font-weight: 300;
    }
    button {
      user-select: none;
      margin-top: 20px;
      border: none;
      background: #5f6ec7;
      color: #fff;
      padding: 0.6rem 1.8rem;
      border-radius: 5px;
      cursor: pointer;
      transition: background 0.3s;
      font-weight: 300;
      font-size: 14px;
      &:hover {
        background: #4d5ec7;
      }
    }
  }
}

.cls-1 {
  fill: #fff;
}
.cls-2 {
  fill: #211f20;
}
.cls-3 {
  fill: #a6c0d8;
}
.cls-4 {
  fill: #8baac4;
}
.cls-5 {
  fill: #94adc1;
}
.cls-6 {
  fill: #7e94a1;
}
.cls-7 {
  fill: #c2c490;
}
.cls-8 {
  fill: #d8daad;
}
.cls-9 {
  fill: #b1b1b1;
}
.cls-10 {
  fill: #e5332a;
}
.cls-11 {
  fill: #f5f5f5;
}
</style>
