<template>
  <div class="gt_base-select" :class="className" v-clickaway="closeDropdown" @focus.prevent="focus">
    <select
      :name="name"
      :id="id"
      :disabled="disabled"
      :form="form"
      :multiple="multiple"
      :required="required"
      class="gt_base-select_model"
    >
      <option :key="item.value" v-for="item in options">{{item.text}}</option>
    </select>
    <template v-if="multiple">
      <div class="gt_base-select_container">
        <div class="gt_base-select_display_hidden">{{longestContent}}</div>
        <div
          class="gt_base-select_display noselect"
          :class="[{'disabled': !options || options.length <= 1}, {'active': opening}, {'hide-arrow': !arrow }]"
          @click="toggleDropdown"
          ref="inputBounder"
          tabindex="0"
        >{{selectedText}}</div>
        <transition :name="'drop'+popupDirection">
          <div class="gt_base-select_dropdown" v-if="opening" ref="dropContain">
            <VuePerfectScrollbar class="scrollBody">
              <div
                class="gt_base-select_dropdown_item noselect"
                :class="{'active': val.indexOf(option.value) > -1}"
                v-for="(option, index) in options"
                :key="option.value+''+index"
                @click="updateValue(option.value)"
              >{{option.text}}</div>
            </VuePerfectScrollbar>
          </div>
        </transition>
      </div>
    </template>

    <template v-else>
      <div class="gt_base-select_container">
        <div class="gt_base-select_display_hidden">{{longestContent}}</div>
        <div
          class="gt_base-select_display noselect"
          :class="[{'disabled': !options || options.length <= 1}, {'active': opening}, {'hide-arrow': !arrow }]"
          @click="toggleDropdown"
          tabindex="0"
        >{{selectedText}}</div>
        <transition :name="'drop'+popupDirection">
          <div class="gt_base-select_dropdown" v-if="opening" ref="dropContain">
            <VuePerfectScrollbar class="scrollBody">
              <div
                class="gt_base-select_dropdown_item noselect"
                :class="{'active': option.value === selected}"
                v-for="(option, index) in options"
                :key="option.value+''+index"
                @click="updateValue(option.value)"
              >{{option.text}}</div>
            </VuePerfectScrollbar>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    id: {
      type: [String, Number, Boolean],
      default: false
    },
    name: {
      type: [String, Number, Boolean],
      default: false
    },
    form: {
      type: [String, Number, Boolean],
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: false,
      default() {
        return [
          {
            text: "Unset",
            value: "unset"
          },
          {
            text: "None",
            value: "none"
          },
          {
            text: "Initial",
            value: "initial"
          },
          {
            text: "Inherit",
            value: "inherit"
          },
          {
            text: "Underline",
            value: "underline"
          },
          {
            text: "Solid",
            value: "solid"
          },
          {
            text: "Dotted",
            value: "dotted"
          },
          {
            text: "Dashed",
            value: "dashed"
          },
          {
            text: "Double",
            value: "double"
          },
          {
            text: "Overline",
            value: "overline"
          },
          {
            text: "Line Through",
            value: "line-through"
          },
          {
            text: "Wavy",
            value: "wavy"
          },
          {
            text: "Blink",
            value: "blink"
          }
        ];
      }
    },
    value: {
      type: [String, Number, Array],
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    arrow: {
      type: Boolean,
      default: true
    },
    className: String
  },
  model: {
    prop: "value",
    event: "input"
  },
  data() {
    return {
      val: "",
      opening: false,
      selected: "none",
      backup: [],
      inVP: true,
      elHeight: 0
    };
  },
  computed: {
    popupDirection() {
      if (this.inVP) {
        return "down";
      }
      return "up";
    },
    selectedText() {
      if (this.multiple) {
        let selected = [];
        this.options.forEach(opt => {
          if (this.val.indexOf(opt.value) > -1) selected.push(opt.text);
        });
        if (selected.length) return selected.join(", ");
        return "None";
      } else {
        let item = this.options.find(item => item.value == this.selected);
        if (item) return item.text;
        return "er";
      }
    },
    longestContent() {
      if (this.options && this.options.length) {
        let max = "";

        this.options.forEach(item => {
          if (item.text.length > max.length) max = item.text;
        });
        if (this.multiple) {
          return max + ", additional";
        }
        return max;
      }
      return "None";
    }
  },
  methods: {
    autoPosition() {
      if (!this.$refs) return;
      if (!this.$refs.inputBounder) return;
      let viewportTop = window.pageYOffset,
        viewportBottom =
          window.pageYOffset +
          window.document.documentElement.clientHeight -
          80,
        rect = this.$refs.inputBounder.getBoundingClientRect(),
        elementTop = rect.top + viewportTop,
        elementBottom = elementTop + rect.height;
      console.log({
        elementBottom,
        viewportBottom
      });
      if (elementBottom + this.elHeight <= viewportBottom) this.inVP = true;
      else this.inVP = false;
    },
    // Dropdown Controlling
    toggleDropdown() {
      // this.backup = JSON.parse(JSON.stringify(this.val));
      if (this.options && this.options.length > 1) this.opening = !this.opening;
      if (!this.opening) {
        // this.elHeight = 0
        if (this.$refs && this.$refs.dropContain)
          this.elHeight = this.$refs.dropContain.getBoundingClientRect().height;
      }
    },
    closeDropdown() {
      if (this.opening) {
        this.blur();
        this.opening = false;
        console.log("CLOSE");
        if (this.multiple) {
          if (this.val && this.val.length)
            if (this.backup.join(",") != this.val.join(",")) {
              console.log({
                backup: this.backup.join(","),
                val: this.val.join(","),
                equal: this.backup.join(",") != this.val.join(",")
              });
              this.backup = this.val;
              this.change();
            }
        }
      }
    },
    // Base
    updateValue(value) {
      if (this.multiple) {
        if (!Array.isArray(this.val)) {
          this.val = [];
        }
        let index = this.val.findIndex(opt => opt === value);
        if (index > -1) this.val.splice(index, 1);
        else this.val.push(value);
        this.onChange();
      } else {
        let updated = this.setValue(value);
        if (updated) this.change();
        if (this.opening) this.opening = false;
      }
    },
    setValue(value) {
      if (this.multiple) {
        this.selected = value;
        this.val = value;
        return true;
      } else {
        if (value != this.val) {
          this.selected = value;
          this.val = value;
          return true;
        }
        return false;
      }
    },
    onChange() {
      this.$emit("input", this.val);
      // this.$emit("onChange", this.id, this.val);
    },
    change() {
      this.$emit("input", this.val);
      this.$emit("change", this.id, this.val);
    },
    focus() {
      this.$emit("focus", this.id, this.val);
    },
    blur() {
      this.$emit("blur", this.id, this.val);
    }
  },
  created() {
    this.$emit("created", this);
    this.setValue(this.value);

    window.addEventListener("scroll", this.autoPosition);
    window.addEventListener("mousewheel", this.autoPosition);
    window.addEventListener("ps-scroll-y", this.autoPosition);
    window.addEventListener("resize", this.autoPosition);
    this.toggleDropdown();
    this.autoPosition();
    this.toggleDropdown();
  },
  watch: {
    value(newV, oldV) {
      if (oldV === newV) return;
      this.setValue(newV);
    },
    opening: {
      immediate: true,
      handler(newV, oldV) {
        const handler = evt => {
          console.log(evt);
        };
        // if (newV) {
        //   window.addEventListener("mousewheel", handler);
        //   window.addEventListener("resize", handler);
        // } else {
        //   window.removeEventListener("mousewheel", handler);
        //   window.removeEventListener("resize", handler);
        // }
      }
    },
    elHeight(newV) {
      this.autoPosition();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.autoPosition);
    window.removeEventListener("mousewheel", this.autoPosition);
    window.removeEventListener("ps-scroll-y", this.autoPosition);
    window.removeEventListener("resize", this.autoPosition);
  }
};
</script>

<style lang="scss" scoped>
$blue: #839bc0;
$text: #333333;
$textShade2: #7f7f7f;
$textShade3: #dadada;
.gt_base-select_model {
  display: none;
}
.ps > .ps__scrollbar-y-rail {
  right: -11px !important;
}
.gt_base-select {
  width: 55px;
  label {
    display: block;
  }
  .gt_base-select_container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .gt_base-select_display {
    position: relative;
    display: flex;
    flex: 1;
    border: none;
    outline: none;
    text-overflow: ellipsis;
    // -webkit-appearance: menulist;
    border-radius: 5px;
    padding: 10px 40px 10px 10px;
    height: 37px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
    overflow: hidden;
    transition: box-shadow 0.3s;

    //new
    display: flex;
    flex: 1;
    border: none;
    outline: none;
    text-overflow: ellipsis;
    // -webkit-appearance: menulist;
    border-radius: 5px;
    padding: 10px 40px 10px 10px;
    height: 37px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: -webkit-box-shadow 0.3s;
    transition: -webkit-box-shadow 0.3s;
    transition: box-shadow 0.3s;
    transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
    border: solid 1px #dddd;

    &.disabled {
      cursor: default;
    }

    &:disabled {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &:not(.hide-arrow) {
      &:after {
        content: "";
        position: absolute;
        right: 12px;
        top: 12px;
        border: solid #ccc;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        transition: all 0.3s ease;
      }
      &.active:after {
        transform: rotate(225deg);
        top: 14px;
      }
    }

    &:focus {
      border: solid 1px #5f6ec7;
    }
  }

  .gt_base-select_display_hidden {
    display: flex;
    padding: 0px 40px 0px 10px;
    height: 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 14px;
  }
  .gt_base-select_dropdown {
    position: absolute;
    top: 42px;
    // left: -40px;
    left: 0px;
    right: 0px;
    // width: calc(100% - 10px);
    width: 100%;
    border-radius: 5px;
    border: solid 1px #eeeeee;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0px #8a8a8a3d;
    padding: 10px 0px 10px 10px;
    z-index: 12;
    min-width: 80px;

    // new
    // box-shadow: 0 0 10px 0px #8a8a8a;
  }
  .gt_base-select_dropdown_item {
    padding: 10px;
    // padding-right: 0;
    margin-right: 10px;
    // max-height: 160px;

    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    border-radius: 5px;
    text-align: left;
    color: #627381;
    transition: all 0.3s;
    background: #fff;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: #f3f5f7;
    }
    &.active {
      border: 1px solid #5f6ec7;
    }

    // new

  }
}
.scrollBody {
  max-height: 165px;
}
</style>
