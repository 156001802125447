import camelCase from "@/plugins/lodash/camelCase.js";
const modules = {};

const requireModule = require.context(
  // The relative path of the components folder
  ".",
  // Whether or not to look in subfolders
  false,
  // /Base[A-Z]\w+\.(vue|js)$/
  /\.js$/
);

requireModule.keys().forEach(fileName => {
  if (fileName === "./index.js") return;
  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ""));
  modules[moduleName] = requireModule(fileName).default;
});
export default modules;
