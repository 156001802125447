<template>
  <div class="gt_model-success" v-if="success.message" @click="closeBodySuccess">
    <div class="gt_model-success-body">
      <div class="gt_model-success-close" @click="closeSuccess">&times;</div>
      <div class="gt_model-success-image">
        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57">
          <g transform="translate(-732 -89)">
            <circle cx="28.5" cy="28.5" r="28.5" transform="translate(732 89)" fill="#d0fccf" />
            <g transform="translate(750 42.003)">
              <g transform="translate(0 67.997)">
                <path
                  d="M21.1,68.311a1.071,1.071,0,0,0-1.515,0L6.76,81.142,1.828,76.21A1.071,1.071,0,0,0,.313,77.725L6,83.414a1.071,1.071,0,0,0,1.515,0L21.1,69.826A1.071,1.071,0,0,0,21.1,68.311Z"
                  transform="translate(0 -67.997)"
                  fill="#148300"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="gt_model-success-info">
        <p>{{success.title || "Success Message"}}</p>
        <span v-html="success.message"></span>
        <!--success.message -->
      </div>
    </div>
  </div>
</template>

<script>
var delayCloseSucess;
import { mapState, mapMutations } from "vuex";
export default {
  name: "SuccessMessage",
  computed: mapState({
    success: state => state.overlay.success
  }),
  methods: {
    ...mapMutations({
      CloseSuccess: "CloseSuccess"
    }),
    closeBodySuccess() {
      let $target = event.target;
      if (!$target.closest(".gt_model-success-body")) {
        clearTimeout(delayCloseSucess);
        this.CloseSuccess();
      }
    },
    closeSuccess() {
      clearTimeout(delayCloseSucess);
      this.CloseSuccess();
    }
  },
  updated() {
    if (this.success.message) {
      clearTimeout(delayCloseSucess);
      delayCloseSucess = setTimeout(() => {
        this.CloseSuccess();
      }, this.success.time);
    }
  }
};
</script>

<style lang="scss" scoped>
.gt_model-success {
  position: fixed;
  z-index: 99999999999999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.gt_model-success-body {
  padding: 50px 60px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 300px;

  .gt_model-success-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    transition: color 0.2s ease;
    &:hover {
      color: #e5332a;
    }
  }
  .gt_model-success-image {
    width: 100px;
    text-align: center;
    margin-bottom: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .gt_model-success-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 16px;
      //   text-transform: uppercase;
      font-weight: 500;
      margin: 0;
      margin-bottom: 15px;
      user-select: none;
    }
    span {
      margin: 0;
      font-size: 14px;
      overflow: auto;
      display: inline;
      text-align: center;
      max-width: 350px;
      line-height: 1.43;
      font-weight: 300;
    }
    button {
      user-select: none;
      margin-top: 20px;
      border: none;
      background: #5f6ec7;
      color: #fff;
      padding: 0.6rem 1.8rem;
      border-radius: 5px;
      cursor: pointer;
      transition: background 0.3s;
      font-weight: 300;
      font-size: 14px;
      &:hover {
        background: #4d5ec7;
      }
    }
  }
}
.cls-1 {
  fill: #fff;
}
.cls-2 {
  fill: #211f20;
}
.cls-3 {
  fill: #a6c0d8;
}
.cls-4 {
  fill: #8baac4;
}
.cls-5 {
  fill: #94adc1;
}
.cls-6 {
  fill: #7e94a1;
}
.cls-7 {
  fill: #c2c490;
}
.cls-8 {
  fill: #d8daad;
}
.cls-9 {
  fill: #b1b1b1;
}
.cls-10 {
  fill: #e5332a;
}
.cls-11 {
  fill: #f5f5f5;
}
</style>
