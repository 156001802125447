<template></template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "SignOut",
  data() {
    return {};
  },
  computed: {},
  methods: {
    ...mapMutations({
      SignOut: "USER_SIGN_OUT"
    }),
    hardReset() {
      const modules = ["PAYOUT", "OVERLAY", "REFERRAL", "SHOP", "USER"];
      modules.forEach(module => {
        this.$store.commit(`${module}_RESET`);
      });
    }
  },
  created() {
    this.SignOut();
    this.hardReset();
    this.$router.push({ name: "SignIn" });
    // window.location.reload();
  }
};
</script>