<template>
    <div class="aff_section">
        <div class="aff-container aff_header">
            <div class="aff_header-logo">
                <router-link :to="{ name: 'Root' }">
                    <svg width="130" height="24" viewBox="0 0 130 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2985_397)">
                        <path d="M26.2148 12.0523C26.2148 16.0539 29.1303 18.9693 33.0556 18.9693C36.962 18.9693 39.725 16.0348 39.7821 11.957L32.6555 11.957V13.8435L37.4002 13.8435C36.9048 15.6537 35.1708 16.8542 33.0556 16.8542C30.3117 16.8542 28.4443 14.8915 28.4443 12.0523C28.4443 9.2321 30.3498 7.26942 33.0556 7.26942C35.0564 7.26942 36.7524 8.37462 37.324 10.0896H39.5916C38.9818 7.1932 36.2569 5.1543 32.9985 5.1543C29.0922 5.1543 26.2148 8.08879 26.2148 12.0523Z" fill="#070714"/>
                        <path d="M46.6201 18.9693C48.9067 18.9693 50.7932 17.6545 51.6126 15.4822H49.3831C48.8496 16.4159 47.8396 16.9876 46.6201 16.9876C44.9242 16.9876 43.7237 15.9586 43.476 14.3008L51.8222 14.3008C51.8412 14.0721 51.8603 13.8244 51.8603 13.5957C51.8603 10.6041 49.7642 8.18407 46.6201 8.18407C43.6094 8.18407 41.3228 10.4897 41.3228 13.5767C41.3228 16.6446 43.6094 18.9693 46.6201 18.9693ZM43.5141 12.5858C43.8762 11.0423 45.0195 10.0896 46.6201 10.0896C48.1826 10.0896 49.364 11.0233 49.7451 12.5858L43.5141 12.5858Z" fill="#070714"/>
                        <path d="M56.2212 18.7216V13.1003C56.2212 11.3472 57.2693 10.0896 58.6412 10.0896C59.937 10.0896 60.642 11.0423 60.642 12.7001V18.7216L62.719 18.7216V13.1003C62.719 11.3472 63.7671 10.0896 65.139 10.0896C66.4157 10.0896 67.1398 11.0423 67.1398 12.7001V18.7216H69.255V12.1857C69.255 9.84187 67.7877 8.20312 65.6154 8.20312C64.2625 8.20312 63.0049 8.88911 62.2617 9.99431C61.671 8.88911 60.5658 8.20312 59.1748 8.20312C57.9171 8.20312 56.7357 8.79383 55.9735 9.76565L55.6115 8.43178H54.1061V18.7216H56.2212Z" fill="#070714"/>
                        <path d="M74.5011 18.7216V14.1864L77.245 14.1864C79.8365 14.1864 81.7802 12.3 81.7802 9.7847C81.7802 7.25036 79.8175 5.38296 77.245 5.38296L72.3097 5.38296V18.7216H74.5011ZM74.5011 7.45997L77.1307 7.45997C78.5598 7.45997 79.5698 8.45084 79.5698 9.7847C79.5698 11.1376 78.5598 12.1094 77.1307 12.1094H74.5011V7.45997Z" fill="#070714"/>
                        <path d="M88.4539 18.9693C89.9402 18.9693 91.236 18.4167 92.1697 17.4639L92.3793 18.7216H94.0943V8.43178L92.4555 8.43178L92.2078 9.70848C91.2741 8.75572 89.9593 8.18407 88.4539 8.18407C85.3861 8.18407 83.0804 10.4707 83.0804 13.5767C83.0804 16.6636 85.3861 18.9693 88.4539 18.9693ZM88.6064 10.2039C90.55 10.2039 91.9601 11.6331 91.9601 13.5767C91.9601 15.5203 90.55 16.9494 88.6064 16.9494C86.6818 16.9494 85.2336 15.5013 85.2336 13.5767C85.2336 11.6521 86.6818 10.2039 88.6064 10.2039Z" fill="#070714"/>
                        <path d="M101.436 18.4167C102.77 18.4167 103.951 17.9594 104.847 17.1972V17.6545C104.847 19.6172 103.551 20.7795 101.76 20.7795C100.464 20.7795 99.2637 20.1698 98.8636 19.0265H96.7484C97.282 21.294 99.1494 22.666 101.779 22.666C104.961 22.666 106.924 20.6652 106.924 17.3687V8.43178H105.533L105.19 9.76565C104.275 8.77478 102.941 8.18407 101.436 8.18407C98.5777 8.18407 96.3483 10.4326 96.3483 13.3099C96.3483 16.1872 98.5777 18.4167 101.436 18.4167ZM101.684 10.1468C103.513 10.1468 104.847 11.4806 104.847 13.3099C104.847 15.1201 103.513 16.454 101.684 16.454C99.8544 16.454 98.5015 15.1011 98.5015 13.3099C98.5015 11.4997 99.8544 10.1468 101.684 10.1468Z" fill="#070714"/>
                        <path d="M114.467 18.9693C116.754 18.9693 118.64 17.6545 119.459 15.4822H117.23C116.696 16.4159 115.686 16.9876 114.467 16.9876C112.771 16.9876 111.571 15.9586 111.323 14.3008H119.669C119.688 14.0721 119.707 13.8244 119.707 13.5957C119.707 10.6041 117.611 8.18407 114.467 8.18407C111.456 8.18407 109.17 10.4897 109.17 13.5767C109.17 16.6446 111.456 18.9693 114.467 18.9693ZM111.361 12.5858C111.723 11.0423 112.866 10.0896 114.467 10.0896C116.029 10.0896 117.211 11.0233 117.592 12.5858H111.361Z" fill="#070714"/>
                        <path d="M121.172 15.4631C121.229 17.5973 122.868 18.9693 125.383 18.9693C127.879 18.9693 129.556 17.6735 129.556 15.6727C129.556 13.1384 127.441 12.8145 125.707 12.5096C124.468 12.319 123.401 12.1285 123.401 11.2138C123.401 10.4707 124.125 9.97525 125.307 9.97525C126.545 9.97525 127.288 10.4897 127.326 11.4806H129.327C129.289 9.47982 127.746 8.18407 125.345 8.18407C122.963 8.18407 121.362 9.44171 121.362 11.2901C121.362 13.672 123.382 14.0721 125.097 14.3579C126.355 14.5675 127.46 14.7962 127.46 15.768C127.46 16.6255 126.583 17.1019 125.459 17.1019C124.22 17.1019 123.306 16.5493 123.249 15.4631H121.172Z" fill="#070714"/>
                        <path d="M23.8573 0.00607304L2.11874 6.80784C2.02836 6.83043 1.98316 6.93212 2.00576 7.02251L3.67795 12.5362C3.70055 12.6266 3.80224 12.6831 3.88133 12.6492L19.8237 7.65523C20.5807 7.41796 21.2247 6.88693 21.5976 6.16382C22.5918 4.25435 23.4053 2.27709 24.0381 0.220747C24.1058 0.0851633 23.9816 -0.0278228 23.8573 0.00607304Z" fill="#FFBB1C"/>
                        <path d="M18.6377 9.54209L5.13586 13.7678C5.06807 13.7904 5.01158 13.8695 5.04547 13.9485L6.4691 18.6036C6.49169 18.6714 6.57078 18.7279 6.64987 18.7053L15.2594 16.0049C15.9034 15.8015 16.4458 15.3496 16.7621 14.7507C17.5982 13.1463 18.2987 11.4628 18.8185 9.72286C18.8524 9.62118 18.7507 9.50819 18.6377 9.54209Z" fill="#E8486C"/>
                        <path d="M13.5074 18.061L7.88069 19.8235C7.8129 19.8348 7.7903 19.9139 7.8016 19.9704L8.99926 23.9136C9.01055 23.9814 9.08964 24.0153 9.14614 23.9927L10.6376 23.5295C11.1799 23.36 11.6318 22.9759 11.903 22.4674C12.6148 21.1116 13.191 19.688 13.643 18.2191C13.6882 18.1175 13.5978 18.0271 13.5074 18.061Z" fill="#476DF2"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_2985_397">
                        <rect width="130" height="24" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </router-link>
            </div>
            <div class="aff_header-right">
                <a target="_blank" href="https://gempages.net/pages/gempages-co-branded-partners" class="aff-btn aff_header-right--link">
                    <span>
                        Explore our program
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3257_1351)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 1.5C11.25 1.30109 11.171 1.11032 11.0303 0.96967C10.8897 0.829018 10.6989 0.75 10.5 0.75H1.5C1.30109 0.75 1.11032 0.829018 0.96967 0.96967C0.829018 1.11032 0.75 1.30109 0.75 1.5V10.5C0.75 10.6989 0.829018 10.8897 0.96967 11.0303C1.11032 11.171 1.30109 11.25 1.5 11.25H10.5C10.6989 11.25 10.8897 11.171 11.0303 11.0303C11.171 10.8897 11.25 10.6989 11.25 10.5V1.5ZM0 1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0H10.5C10.8978 0 11.2794 0.158035 11.5607 0.43934C11.842 0.720644 12 1.10218 12 1.5V10.5C12 10.8978 11.842 11.2794 11.5607 11.5607C11.2794 11.842 10.8978 12 10.5 12H1.5C1.10218 12 0.720644 11.842 0.43934 11.5607C0.158035 11.2794 0 10.8978 0 10.5V1.5ZM4.3905 8.10225C4.32018 8.17266 4.22478 8.21226 4.12527 8.21233C4.02575 8.2124 3.93029 8.17294 3.85988 8.10262C3.78946 8.03231 3.74986 7.9369 3.74979 7.83739C3.74972 7.73788 3.78918 7.64241 3.8595 7.572L6.93225 4.5H4.85625C4.75679 4.5 4.66141 4.46049 4.59108 4.39016C4.52076 4.31984 4.48125 4.22446 4.48125 4.125C4.48125 4.02554 4.52076 3.93016 4.59108 3.85984C4.66141 3.78951 4.75679 3.75 4.85625 3.75H7.8375C7.93696 3.75 8.03234 3.78951 8.10266 3.85984C8.17299 3.93016 8.2125 4.02554 8.2125 4.125V7.10625C8.2125 7.20571 8.17299 7.30109 8.10266 7.37142C8.03234 7.44174 7.93696 7.48125 7.8375 7.48125C7.73804 7.48125 7.64266 7.44174 7.57234 7.37142C7.50201 7.30109 7.4625 7.20571 7.4625 7.10625V5.03025L4.3905 8.10225Z" fill="currentColor"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_3257_1351">
                            <rect width="12" height="12" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
}
</script>

<style lang="scss" scoped>
.aff_section {
    background: #fff;
    height: 89px;
    width: 100%;
    border-bottom: 1px solid #E3E3E8;
    z-index: 1;
    position: relative;

    @media screen and (max-width: 375px) {
        height: 85px;
    }
}

.aff_header {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-right {
        height: 100%;

        &--link {
            border: unset;
            height: 100%;
            color: #161723;
            padding: unset;
            position: relative;
            padding-left: 0;
            padding-right: 0;
            display: flex;
            align-items: center;
            font-family: 'Inter Tight';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.5px;
            border-radius: unset;
            position: relative;

            &:hover {
                color: #476DF2;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    height: 2px;
                    width: 100%;
                    background-color: #476DF2;
                }
            }

            @media screen and (max-width: 375px) {
                font-size: 14px;
            }
        }
    }
}
</style>