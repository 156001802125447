<template>
  <main>
    <Menu />

    <Sidebar />

    <section class="agem_main">
      <div class="main">
        <router-view></router-view>
      </div>
    </section>
    <ModalTerms />
    <PortalTarget name="modals"></PortalTarget>
    <Success />
    <Error />
  </main>
</template>

<script>
import Menu from "./Menu";
import Sidebar from "./Sidebar";
import Success from "@/components/message/Success.vue";
import Error from "@/components/message/Error.vue";
import ModalTerms from "@/layouts/ModalTerms.vue";
import { mapState } from "vuex";
import { sentrySetIdentification } from "../utils/sentry";

export default {
  name: "Default",
  components: {
    Menu,
    Sidebar,
    ModalTerms,
    Success,
    Error,
  },
  created() {
    if (this.user) {
      sentrySetIdentification(this.user);
    }
  },
  mounted() {
    if (this.user) {
      sentrySetIdentification(this.user);
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.info,
    }),
  },
};
</script>
<style lang="scss" scoped>
.alert-box {
  color: #555;
  border-radius: 10px;
  font-family: Tahoma, Geneva, Arial, sans-serif;
  font-size: 11px;
  padding: 10px 10px 10px 36px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-bottom: 30px;
  padding: 20px;
  font-size: 16px;
}

.alert-box span {
  font-weight: bold;
}

.error {
  // background: #ffecec url('images/error.png') no-repeat 10px 50%;
  border: 1px solid #f5aca6;
}
.success {
  // background: #e9ffd9 url('images/success.png') no-repeat 10px 50%;
  border: 1px solid #a6ca8a;
}
.warning {
  background: #fff8c4;
  border: 1px solid #f2c779;
}
.information {
  background: #e3f7fc;
  border: 1px solid #8ed9f6;
}
</style>
