const TYPES = {
  RESET: "OVERLAY_RESET"
}
const defaultState = () => {
  return {
    error: {
      title: null,
      message: null
    },
    success: {
      title: null,
      message: null,
      time: 2000
    },
    loading: {
      count: 0
    },
    term: {
      show: false
    }
  };
}
export default {
  state: defaultState(),
  mutations: {
    OpenError(state, error) {
      state.error.title = error.title || "Warning Message";
      state.error.message = error.message || "Unknown Error Occur";
    },
    CloseError(state) {
      state.error.message = null;
    },
    OpenSuccess(state, success) {
      state.success.title = success.title || "Success Message";
      state.success.message = success.message || "Job Success";
      if (success.time) {
        state.success.time = success.time;
      }
    },
    CloseSuccess(state) {
      state.success.message = null;
      state.success.time = 2000;
    },
    OpenLoading(state) {
      state.loading.count++;
    },
    CloseLoading(state) {
      state.loading.count--;
      if (state.loading.count < 0) state.loading.count = 0;
    },
    ForceCloseLoading(state) {
      state.loading.count = 0;
    },
    OpenTerm(state) {
      state.term.show = true;
    },
    CloseTerm(state) {
      state.term.show = false;
    },
    [TYPES.RESET](state) {
      let initial = defaultState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
      console.log({
        message: TYPES.RESET,
        state
      })
    }
  }
};
