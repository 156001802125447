import axios from "axios";
import Cookie from "@/utils/cookie.js";
import store from "@/store/index.js";
import { sentryCaptureException } from "../utils/sentry";

export const DBInstance = {
  get baseURL() {
    if (process.env.NODE_ENV == "development") {
      return "http://localhost:8000";
    }
    return window.location.origin;
  },
};

export default () => {
  let settings = {
    baseURL: DBInstance.baseURL,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Cookie.get(Cookie.key),
    },
  };
  // console.log({token: Cookie.get(Cookie.key), key: Cookie.key})
  let http = axios.create(settings);

  // On request
  http.interceptors.request.use(
    (config) => {
      // console.log(config)
      store.commit("loader/START_LOADING");
      return config;
    },
    (error) => {
      sentryCaptureException(
        "API Fetch Failed - Request",
        error?.message || "",
        {
          data: error?.response?.data,
        }
      );
      store.commit("loader/FINISH_LOADING");
      return Promise.reject(error);
    }
  );

  // On response
  http.interceptors.response.use(
    (response) => {
      store.commit("loader/FINISH_LOADING");
      return response;
    },
    (error) => {
      sentryCaptureException(
        "API Fetch Failed - Response",
        error?.message || "",
        {
          data: error?.response?.data,
        }
      );
      store.commit("loader/FINISH_LOADING");
      return Promise.reject(error);
    }
  );

  return http;
};
