import store from "@/store";
export function catcher(error) {
    console.error({error, location: "catcher"})
    if (error) {
        if (error.response) {
            // if (error.response.status == 401) {
            //     store.commit("OpenError", {
            //         message: "You are not logged in.<br>We will redirect after 5 seconds"
            //     });
            //     setTimeout(function () {
            //         window.location.href = "/";
            //     }, 5000);
            // } else 
            if (error.response.status == 400) {
                store.commit("OpenError", {
                    message: error.response.data.message ? error.response.data.message : error.response.statusText
                });
            } else if (error.response.status == 500) {
                store.commit("OpenError", {
                    message: error.response.data.message ? error.response.data.message : "Server encountered a problem.<br>Please try again later"
                });
            } else {
                store.commit("OpenError", {
                    message: error.response.data.message ? error.response.data.message : "Oops. Something has broken.<br>Please try again later"
                });
            }
        }
        return Promise.reject(error)
    } else {
        store.commit("OpenError", {
            message: "Server encountered a problem.<br>Please try again later"
        });
        return Promise.reject(error)
    }
}