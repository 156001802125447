<template>
  <div class="gfLoader">
    <div :class="size == 'small' ? 'gfLoader-frame-small' : 'gfLoader-frame-medium'">
      <div class="gfLoader-Circle">
        <svg class="gfLoader-Circle-shape" viewBox="25 25 50 50">
          <circle
            class="gfLoader-Circle-track"
            cx="50"
            cy="50"
            r="23"
            fill="none"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke="rgba(10,10,58,0.1)"
          />
          <circle
            class="gfLoader-Circle-path"
            cx="50"
            cy="50"
            r="23"
            fill="none"
            stroke-width="2"
            stroke-miterlimit="10"
          />
        </svg>
      </div>

      <div class="gfLoader-ColorfulLogo" v-if="size != 'small'">
        <svg class="gfLoader-ColorfulLogo-shape" viewBox="0 0 28 28">
          <path
            d="M26.7,2c0.1,0,0.1,0,0.2,0s0.1,0.1,0,0.2c-0.7,2.1-1.6,4.2-2.6,6.2
		        	c-0.4,0.7-1.1,1.3-1.9,1.6L5.1,15.2c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1L3,9.3c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1L26.7,2z
		        	M21.2,12.2c0.1,0,0.1,0,0.2,0s0.1,0.1,0,0.2c-0.6,1.8-1.3,3.6-2.2,5.2c-0.3,0.6-0.9,1.1-1.6,1.3l-9.3,2.8c-0.1,0-0.2,0-0.2-0.1
		        	l-1.5-4.9c0-0.1,0-0.2,0.1-0.2L21.2,12.2z M15.1,21.3c0,0,0.1,0,0.1,0s0,0.1,0,0.1c-0.5,1.5-1.1,3-1.9,4.4c-0.3,0.5-0.8,0.9-1.4,1.1
		        	l-0.8,0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1L9.7,23c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1L15.1,21.3z"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GLoading",
  props: {
    size: String
  }
};
</script>
<style lang="scss" scoped>
// GEM Loader
.gfLoader-frame {
  display: inline-block;
  position: relative;
  width: 120px;
  padding-top: 120px;
}

.gfLoader-Circle {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 120px;
}

.gfLoader-ColorfulLogo {
  display: block;
  position: absolute;
  left: 8px;
  top: 8px;
  width: 104px;
}

.gfLoader-Circle-shape {
  -webkit-animation: gfLoader-Circle-rotate 0.9s linear infinite;
  animation: gfLoader-Circle-rotate 0.9s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  vertical-align: top;
  margin: auto;
}

.gfLoader-Circle-path {
  stroke-dasharray: 30, 200;
  stroke-dashoffset: 0;
  -webkit-animation: gfLoader-Circle-dash1 1.5s ease-in-out infinite,
    gfLoader-Circle-color 1.5s ease-in-out infinite;
  animation: gfLoader-Circle-dash1 3s ease-in-out infinite,
    gfLoader-Circle-color 2s ease-in-out infinite;
  stroke-linecap: round;
}

.gfLoader-ColorfulLogo-shape {
  vertical-align: middle;
  -webkit-animation: gfLoader-ColorfulLogo-color 2s ease-in-out infinite;
  animation: gfLoader-ColorfulLogo-color 2s ease-in-out infinite;
}

.gfLoader-3Dots {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.9);
  letter-spacing: 1px;
}

.gfLoader-3Dots-dot1,
.gfLoader-3Dots-dot2,
.gfLoader-3Dots-dot3 {
  letter-spacing: -1.5px;
  font-weight: 700;
}

.gfLoader-3Dots-dot1 {
  -webkit-animation: gfLoader-3Dots-dot1 1s infinite;
  animation: gfLoader-3Dots-dot1 1s infinite;
}

.gfLoader-3Dots-dot2 {
  -webkit-animation: gfLoader-3Dots-dot2 1s infinite;
  animation: gfLoader-3Dots-dot2 1s infinite;
}

.gfLoader-3Dots-dot3 {
  -webkit-animation: gfLoader-3Dots-dot3 1s infinite;
  animation: gfLoader-3Dots-dot3 1s infinite;
}

@-webkit-keyframes gfLoader-Circle-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes gfLoader-Circle-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes gfLoader-Circle-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes gfLoader-Circle-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@-webkit-keyframes gfLoader-Circle-color {
  0%,
  100%,
  21% {
    stroke: #547aff;
  }

  33%,
  54% {
    stroke: #e84879;
  }

  66%,
  87% {
    stroke: #ffc439;
  }
}

@keyframes gfLoader-Circle-color {
  0%,
  100%,
  21% {
    stroke: #547aff;
  }

  33%,
  54% {
    stroke: #e84879;
  }

  66%,
  87% {
    stroke: #ffc439;
  }
}

@-webkit-keyframes gfLoader-ColorfulLogo-color {
  0%,
  100%,
  21% {
    fill: #547aff;
  }

  33%,
  54% {
    fill: #e84879;
  }

  66%,
  87% {
    fill: #ffc439;
  }
}

@keyframes gfLoader-ColorfulLogo-color {
  0%,
  100%,
  21% {
    fill: #547aff;
  }

  33%,
  54% {
    fill: #e84879;
  }

  66%,
  87% {
    fill: #ffc439;
  }
}

@-webkit-keyframes gfLoader-3Dots-dot1 {
  0%,
  100% {
    opacity: 0;
  }

  25%,
  50%,
  75% {
    opacity: 1;
  }
}

@keyframes gfLoader-3Dots-dot1 {
  0%,
  100% {
    opacity: 0;
  }

  25%,
  50%,
  75% {
    opacity: 1;
  }
}

@-webkit-keyframes gfLoader-3Dots-dot2 {
  0%,
  100%,
  25% {
    opacity: 0;
  }

  50%,
  75% {
    opacity: 1;
  }
}

@keyframes gfLoader-3Dots-dot2 {
  0%,
  100%,
  25% {
    opacity: 0;
  }

  50%,
  75% {
    opacity: 1;
  }
}

@-webkit-keyframes gfLoader-3Dots-dot3 {
  0%,
  100%,
  25%,
  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }
}

@keyframes gfLoader-3Dots-dot3 {
  0%,
  100%,
  25%,
  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }
}

.gfLoader-frame {
  position: absolute;
  top: 40%;
  left: calc(50% - 60px);
}

.gfLoader-3Dots {
  font-size: 16px;
  white-space: nowrap;
}

.gfLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #fff;
}
.gfLoader-frame-small {
  position: absolute;
  width: 30px;
  height: 30px;
}
.gfLoader-frame-medium {
  position: absolute;
  width: 60px;
  height: 60px;
}

.gfLoader-Circle {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gfLoader-ColorfulLogo {
  position: absolute;
  display: block;
  top: 27%;
  left: 27%;
  width: 46%;
  height: 46%;
}

.gfLoader-Circle-shape {
  display: block;
  -webkit-animation: gfLoader-Circle-rotate 0.9s linear infinite;
  animation: gfLoader-Circle-rotate 0.9s linear infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.gfLoader-Circle-path {
  stroke-dasharray: 30, 200;
  stroke-dashoffset: 0;
  -webkit-animation: gfLoader-Circle-dash1 1.5s ease-in-out infinite,
    gfLoader-Circle-color 1.5s ease-in-out infinite;
  animation: gfLoader-Circle-dash1 3s ease-in-out infinite,
    gfLoader-Circle-color 2s ease-in-out infinite;
  stroke-linecap: round;
}

.gfLoader-frame-small .gfLoader-Circle-path,
.gfLoader-frame-small .gfLoader-Circle-shape {
  stroke-width: 4px;
}

.gfLoader-ColorfulLogo-shape {
  -webkit-animation: gfLoader-ColorfulLogo-color 2s ease-in-out infinite;
  animation: gfLoader-ColorfulLogo-color 2s ease-in-out infinite;
}
</style>