import {FETCH} from "@/utils/list.js";
const TYPES = {
  FETCH_SHOP: "SHOP_FETCH_INFO",
  RESET: "SHOP_RESET"
}
const defaultState = () => {
  return {
    shops: [],
  }
}
export default {
  state: defaultState(),

  mutations: {
    [TYPES.FETCH_SHOP](state, list) {
      let comparer = (obj, item) => {
        return obj.id === item.id;
      };
      FETCH(state.shops, list, comparer, null);
    },

    [TYPES.RESET](state) {
      let initial = defaultState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
      console.log({
        message: TYPES.RESET,
        state
      })
    }
  }
};
